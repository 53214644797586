

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';
import {
  IFormField,
  GraphicalPDFFormField,
  PDFFieldData,
  PDFKeyValuePair,
  GraphicalPDFSignatureField,
} from 'client-website-ts-library/types/Forms/FieldTypes';
import { Context } from 'client-website-ts-library/types';

import { PDFFormSubfieldChangedData } from '@/app_code/Forms';

import ErrorList from '../../ErrorList.vue';
import FormGraphicalPDFFieldPage from './FormGraphicalPDFFieldPage.vue';

@Component({
  components: {
    ErrorList,
    FormGraphicalPDFFieldPage,
  },
})
export default class FormGraphicalPDFField extends Vue implements IFormField {
  @Prop()
  private readonly formType!: string;

  @Prop()
  private readonly formContext!: Context;

  @Prop()
  private readonly def!: GraphicalPDFFormField;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private value: PDFFieldData = {};

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get elemId() {
    return `field_${this.def.FieldId}`;
  }

  getValue(): FormValue {
    const kvps: (string | PDFKeyValuePair)[] = Object.keys(this.value)
      .map((key) => ({ Key: key, Value: this.value[key] } as PDFKeyValuePair))
      .map((data) => {
        if (data instanceof File) {
          return data;
        }

        if (data.Value instanceof Blob) {
          const file = data.Value as any;

          file.lastModifiedDate = new Date();
          file.name = `${this.def.Key}_${data.Key}`;

          return file;
        }

        return JSON.stringify(data);
      });

    return {
      Key: this.def.Key,
      Value: kvps,
    };
  }

  setValue(value: FormValue) {
    if (value.Value instanceof FormValue) {
      this.value = value.Value as PDFFieldData;
    }
  }

  handleSubfieldChanged(data: PDFFormSubfieldChangedData) {
    (this.value as PDFFieldData)[data.def.Key] = data.value;
  }

  getSigFieldsForPage(page: number): GraphicalPDFSignatureField[] {
    return this.def.SignatureFields.filter((f) => f.PageNumber === page);
  }
}
